<!-- 登录 -->
<template>
<div class="login-wrap">
  <div class="container main-1">
    <h2>{{ isQQlogin ? 'QQ' : '用户' }}登录 <span>{{ isQQlogin ? '请设置网站用户名和密码' : '没有账号？' }}<nuxt-link to="/register" class="yf-login-text" v-show="!isQQlogin">免费注册</nuxt-link></span></h2>
    <section class="form">
      <div class="form-item clearfix">
        <label for="username" class="left">用户名：</label>
        <input
          class="login-input left"
          :class="{ success: userName__state === 'success', error: userName__state === 'error' }"
          type="text"
          v-model.trim="userName"
          autofocus
          tabindex="1"
          @keydown.enter="$refs.password.focus()"
          @blur="checkName"
          @focus="userName__state = 'normal'">
        <div class="login-tip left">
          <p class="placeholder" v-if="userName__state === 'normal'">{{ isQQlogin ? '请输入用户名作为您QQ登录的昵称' : '请输入您的昵称/手机/邮箱' }}</p>
          <p class="error yf-text_red" v-else-if="userName__state === 'error'">
            <i class="iconfont icon-cuowu"></i> {{ userName__state_msg }}
          </p>
          <p class="success" v-else-if="userName__state === 'success'">
            <i class="iconfont icon-zq"></i>
          </p>
        </div>
      </div>
      <div class="form-item clearfix">
        <label for="passWord" class="left">密&nbsp;&nbsp;&nbsp;码：</label>
        <input
          ref="password"
          class="login-input left"
          :class="{ success: passWord__state === 'success', error: passWord__state === 'error' }"
          type="passWord"
          v-model="passWord"
          tabindex="2"
          maxlength="16"
          @keydown.enter="handleLogin"
          @blur="checkpassWord"
          @focus="passWord__state = 'normal'">
        <div class="login-tip left">
          <p class="placeholder" v-if="passWord__state === 'normal'">6-16位，英文（区分大小写）、数字或常用符号</p>
          <p class="error yf-text_red" v-else-if="passWord__state === 'error'">
            <i class="iconfont icon-cuowu"></i> {{ passWord__state_msg }}
          </p>
          <p class="success" v-else-if="passWord__state === 'success'">
            <i class="iconfont icon-zq"></i>
          </p>
        </div>
      </div>
      <div class="form-item form-item-1 clearfix" v-show="!isQQlogin">
        <p class="left checkbox-wrap" @click="remember = !remember" title="不是自己的电脑请勿勾选此项！"><input type="checkbox" class="checkbox" v-model="remember" tabindex="3"> 记住密码</p>
        <p class="right yf-login-text" tabindex="4"><nuxt-link to="/forgot" class="yf-login-text">忘记密码</nuxt-link></p>
      </div>
      <div class="form-item form-item-1">
        <button type="button" class="login-btn" tabindex="5" @click="handleLogin">立即登录</button>
      </div>
      <div class="form-item form-item-1" v-show="!isQQlogin">
        <div class="other-login clearfix">
          <span class="left"></span>
          <span class="left">使用其他方式登录</span>
          <span class="left"></span>
        </div>
        <div class="qq-img center">
          <i class="iconfont icon-qq" @click="toLogin"></i>
        </div>
      </div>
    </section>
    <section class="login-ad">
      <img src="~assets/login-ad.jpg" alt="投资者教育平台">
    </section>
  </div>
</div>
</template>

<script>
import Dialog from '~/utils/Dialog'

export default {
  name: 'login',
  scrollToTop: true,
  head () {
    return {
      title: '登录-一富财经',
      script: [
        { src: 'https://connect.qq.com/qc_jssdk.js' }
      ]
    }
  },
  async asyncData ({ from }) {
    // from参数只有在非服务端渲染时再有数据
    return {
      from: from ? from.fullPath : undefined
    }
  },
  data () {
    return {
      userName: '',
      userName__state: 'normal',
      userName__state_msg: '',

      passWord: '',
      passWord__state: 'normal',
      passWord__state_msg: '',

      remember: false,

      isQQlogin: false,
      qqOpenId: ''
    }
  },
  created () {
    let hash = this.$route.hash
    if (hash.indexOf('#access_token') > -1) {
      this.isQQlogin = true
      this.handleHash(hash.slice(1))
    }
  },
  methods: {
    checkName () {
      if (this.userName === '') {
        this.userName__state = 'error'
        this.userName__state_msg = '请输入用户名'
        return false
      } else {
        this.userName__state = 'success'
        return true
      }
    },
    checkpassWord () {
      if (this.passWord === '' || this.passWord.length < 6) {
        this.passWord__state = 'error'
        if (this.passWord === '') {
          this.passWord__state_msg = '请输入密码'
        } else if (this.passWord.length < 6) {
          this.passWord__state_msg = '密码至少六位数'
        }
        return false
      } else {
        this.passWord__state = 'success'
        return true
      }
    },
    handleLogin (e) {
      if (this.isQQlogin && this.checkName() && this.checkpassWord()) {
        e.target.disabled = true
        this.qqLogin({ userName: this.userName, qqOpenId: this.qqOpenId, passWord: this.passWord }).finally(() => {
          e.target.disabled = false
        })
      } else if (this.checkName() && this.checkpassWord()) {
        e.target.disabled = true
        this.$axios._post('/Auth/Login', {
          username: this.userName,
          password: this.passWord,
          type: 0
        }).then(res => {
          e.target.disabled = false
          this.logined(res.token)
        }).catch(err => {
          e.target.disabled = false
          this.userName__state = this.passWord__state = 'error'
          this.userName__state_msg = this.passWord__state_msg = '用户名或密码输入错误'
          console.log(err)
        })
      }
    },
    logined (token) {
      // 设置token
      this.$cookies.set('yf_jwt', token, this.remember ? 2000 : undefined)
      this.remember && this.$cookies.set('yf_remember', this.remember ? 1 : 0, 2000)
      // 因为更新用户信息要触发nuxtServerInit方法，所以需要页面重载
      let baseUrl = location.href.slice(0, location.href.indexOf('/login'))
      if (this.from && (this.from !== '/login' || '/register' || '/forgot')) {
        // 非登录、注册、找回密码页面进来此页登录后需要跳回原页面，否则跳转到网站首页
        location.href = baseUrl + this.from
      } else {
        location.href = baseUrl
      }
    },
    toLogin () {
      QC.Login.showPopup({
        appId: '101531835',
        redirectURI: 'https://www.yifucj.com/login'
      })
    },
    handleHash (hash) {
      let data = hash.split('&')
      let access_token = data[0].split('=')
      this.$axios._post('/Auth/QqOpenId', { accessToken: access_token[1] }).then(res => {
        this.qqOpenId = res
        this.qqLogin({ qqOpenId: res })
      })
    },
    qqLogin (params) {
      return this.$axios._post('/Auth/QqLogin', params).then(res => {
        if (res.login === false) {
          // this.isQQlogin = true
        } else if (res.token) {
          this.logined(res.token)
        }
      })
    }
  }
}
</script>
